import React from "react"
import Back from "../common/back/Back"
import "./contact.css"

const Contact = () => {
  const map = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11518.566510937457!2d-79.30948134712831!3d43.80104886191217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d3c3d5c94957%3A0x5b2e42141bca2c7b!2s25%20Silver%20Springs%20Blvd%20%231604%2C%20Scarborough%2C%20ON%20M1V%201M9%2C%20Canada!5e0!3m2!1sen!2sin!4v1733331492909!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" '
  return (
    <>
      <Back/>
      <section className='contacts padding'>
        <div className='container shadow flexSB contactContent'>
          <div className='left row'>
            <iframe src={map}></iframe>
          </div>
          <div className='right row'>
            <h1>Contact us</h1>
            <p>We're open for any suggestion or just to have a chat</p>

            <div className='items grid2'>
              <div className='box'>
                <h4>ADDRESS:</h4>
                <p>1604-25 SILVER SPRINGS BLVD., SCARBOROUGH, ONTARIO, M1V 1M9 CANADA.</p>
              </div>
              <div className='box'>
                <h4>EMAIL:</h4>
                <p> info@chemmozhi.ca</p>
              </div>
              <div className='box'>
                <h4>PHONE:</h4>
                <p>+1 (647) 881-3613</p>
              </div>
            </div>

            <form action=''>
              <div className='flexSB'>
                <input type='text' placeholder='Name' />
                <input type='email' placeholder='Email' />
              </div>
              <input type='text' placeholder='Subject' />
              <textarea cols='30' rows='10' placeholder='Content'>
              </textarea>
              <button className='primary-btn'>SEND MESSAGE</button>
            </form>

            <h3>Follow us here</h3>
            <span className="followUs">
              <a> FACEBOOK</a>
              <a> INSTAGRAM</a>
            </span>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
