import React from "react";
import { useHistory } from "react-router-dom";

const Head = () => {
  const history = useHistory();

  return (
    <>
      <section className='head'>
        <div className='container flexSB'>
          <div className='logo' onClick={()=>history.push("/")}>
            <img src="/images/logo/logo1.png" alt="Logo" className="headerLogo" />
            <h1>CHEMMOZHI ACADEMY</h1>
            <span className="motoFont">Preserving Language & Culture, Empowering the Future...</span>
          </div>

          <div className='social'>
            <i className='fab fa-facebook-f icon'></i>
            <i className='fab fa-instagram icon'></i>
            {/* <i className='fab fa-twitter icon'></i>
            <i className='fab fa-youtube icon'></i> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Head;
