import React from 'react';
import './ScrollToTopButton.css';  // Importing the CSS file for styling

const ScrollToTopButton = () => {
  
  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button className="scroll-to-top" onClick={scrollToTop}>
      <span>^</span>
    </button>
  );
};

export default ScrollToTopButton;
