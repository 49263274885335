import React from "react";
import "./about.css";
import Back from "../common/back/Back";
import { Fade } from "react-reveal";

const AboutUs = () => {
    return (
        <>
            <Back />
            <section className="padding">
                <div className="container shadow aboutUs">
                    <h1>About Us</h1>
                    <div className="vision-container">
                        <div className="vision">
                            <span className="visionHead">Vision</span>
                            <p>
                                "For the benefit of the current and future generations of Tamils transplanted from their ethnic roots, constituting the Tamil Diaspora."
                            </p>
                        </div>
                    </div>
                    <div className="aboutUsCard">
                        <div className="card">
                            <div className="textContainer1">
                                <p>
                                    For the benefit of the younger generation, Grade level examinations (Levels 1-7) will be conducted in Tamil, Dance, Music, and Yoga. Fostering multicultural understanding and mainstream values through active participation in local, provincial, and national events. Promoting the principles of the Human Rights Charters and Freedoms.
                                </p>
                            </div>
                            <div className="imageContainer">
                                <img
                                    src="/images/blog/multic.webp"
                                    alt="Multicultural activities"
                                    className="cardImage"
                                />
                            </div>
                        </div>
                        <div className="card">
                            <div className="imageContainer">
                                <img
                                    src="/images/blog/toronto.webp"
                                    alt="Toronto Cityscape"
                                    className="cardImage"
                                />
                            </div>
                            <div className="textContainer">
                                <p>
                                    Toronto is one of the mega cities in Canada, consisting of six large regions, where Tamils live in large numbers. About 30 years ago, Tamil was not known in Canada, whereas now Tamils are a visible minority and they hold fairly elevated positions in the public sector.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="process">

                        <div className="processCard">
                            <h1>Why Chemmozhi Academy?</h1>
                            <div className="points">
                                <p>This will be an intake facility – for students to follow and complete programs wholly created, presented and monitored by an accredited “A” grade University in Tamil Nadu, India.</p>
                            </div>
                            <div className="points">
                                <p>Instruction for Degree, Diploma and Masters Programs in Tamil, Dance, Music and Yoga are also handled full time by Professors of the Canada Chemmozhi Academy. Degrees and Diplomas earned are with accreditation from the parent University and benefits thereof are therefore identical to those accruing from completion of study programs at Universities in India. </p></div>
                            <div className="points"><p>
                                Diploma in Tamil Teacher Training – A two-year Tamil Teacher Training program in order for the local Tamil Teachers to get trained in the specific field.</p>
                            </div>
                        </div>

                    </div>
                    <div className="activities">
                    <h1>What We Do</h1>
                        <Fade left cascade>
                            <ul className="activitiesList">
                                <Fade left cascade>
                                    <li>Collaborate on certificate, diploma, and degree programs with other educational institutions.</li>
                                </Fade>
                                <Fade right cascade>
                                    <li>Organize national and international meetings, seminars, lectures, workshops, conferences, training programs, camps, ceremonies, clubs, contests, volunteering, exhibitions, and events.</li>
                                </Fade>
                                <Fade left cascade>
                                    <li>Provide resources and support for research and development.</li>
                                </Fade>
                                <Fade right cascade>
                                    <li>Guide and support newcomers to Canada with education, employment, permanent residency, and tourism opportunities.</li>
                                </Fade>
                                <Fade left cascade>
                                    <li>Encouraging students to improve their native language proficiency through online and offline academic activities that follow global language standards.</li>
                                </Fade>
                                <Fade right cascade>
                                    <li>Provide family services to prevent violence, homelessness, mental health crises, and hospitalization. Services include crisis intervention, counseling, mediation, case conferences, and continuous support for seniors, adults, and children.</li>
                                </Fade>
                                <Fade left cascade>
                                    <li>Provide interpretation and translation services for the community and government.</li>
                                </Fade>
                                <Fade right cascade>
                                    <li>Publish educational and social resources such as textbooks, publications, journals, movies, photos, and sounds.</li>
                                </Fade>
                                <Fade left cascade>
                                    <li>To equip professionals with skills, training, and resources to maximize their potential and achieve success.</li>
                                </Fade>
                            </ul>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutUs;
