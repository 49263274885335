import React, { useEffect, useState } from "react";
import Heading from "../../common/heading/Heading";


const Hero = () => {
  const paragraph =
    "Empowering students to lead, innovate, and make a positive impact on the world through excellence in education.";
  const [visibleText, setVisibleText] = useState("");
  const [visibleIndex, setVisibleIndex] = useState(0);

  useEffect(() => {
    if(visibleIndex < paragraph.length){
      const interval = setInterval(() => {
        setVisibleText(prevTest => prevTest + paragraph[visibleIndex]);
        setVisibleIndex(prevIndex=> prevIndex+1);
      },22  )
      return () => clearInterval(interval);
    }
  }, [visibleIndex]);

  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="row">
            <Heading className = "customSubTitle" subtitle="Welcome to Chemmozhi Academy" title="Best Education Expertise" />
            <p>{visibleText}</p>
            <div className="button">
              <button className="primary-btn">
                GET STARTED NOW <i className="fa fa-long-arrow-alt-right"></i>
              </button>
              <button>
                VIEW COURSE <i className="fa fa-long-arrow-alt-right"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="margin"></div>
    </>
  );
};

export default Hero;
